.doc-body {
  display: flex;
  min-height: 600px;
  background-color: #fff;
}
.doc-body .doc-category {
  width: 180px;
}
.doc-body .doc-category .publish-doc {
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid #e7e7eb;
}
.doc-body .doc-category .doc-tree {
  padding: 20px;
}
.doc-body .doc-list {
  flex-grow: 1;
  border-left: 1px solid #e7e7eb;
  padding: 20px;
}
.doc-body .doc-list .doc-title {
  font-size: 24px;
  text-align: center;
  margin: 30px 0;
}
.doc-body .doc-list .doc-content .mavon-show-card {
  z-index: 1;
}
.doc-body .doc-list .handle-bar {
  position: absolute;
  top: 10px;
  right: 10px;
}
