.doc-body {
    display: flex;
    min-height: 600px;
    background-color: #fff;

    .doc-category {
        width: 180px;

        .publish-doc {
            padding: 20px;
            text-align: center;
            border-bottom: 1px solid #e7e7eb;
        }

        .doc-tree {
            padding: 20px;
        }
    }

    .doc-list {
        flex-grow: 1;
        border-left: 1px solid #e7e7eb;
        padding: 20px;

        .doc-title {
            font-size: 24px;
            text-align: center;
            margin: 30px 0;
        }

        .doc-content {
            .mavon-show-card {
                z-index: 1;
            }
        }

        .handle-bar {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
}
@primary-color: #7f7fd5;@link-color: #86a8e7;